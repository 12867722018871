html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/open-sans-v17-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("/fonts/open-sans-v17-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/open-sans-v17-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("/fonts/open-sans-v17-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/open-sans-v17-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/open-sans-v17-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/open-sans-v17-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("/fonts/open-sans-v17-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/open-sans-v17-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/open-sans-v17-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/open-sans-v17-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/open-sans-v17-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

root {
  height: 100%;
}
