.App {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 40px 1px;
  grid-template-areas: "Logo Logo Logo" "main main main";
  height: 100%;
  padding: 10px 20px;
}
@media (min-width: 700px) {
  .App {
    grid-template-areas: "Logo . ." "main main main";
  }
}

.logo {
  grid-area: Logo;
  img {
    max-width: 100%;
  }
}

.main {
  grid-area: main;
  place-content: center;
  display: grid;

  a {
    &.button {
      font-weight: 600;
      padding: 20px 15px;
      background-color: #ce1d97;
      border-radius: 25px;
      line-height: 1.6em;
      color: #fff;
      text-decoration: none;
      font-size: 1.3em;
      min-width: min-content;
      text-align: center;
      margin-top: 1em;
      margin-bottom: 2em;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1.4em;
  }
}
